<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '添加技能' : '修改技能'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="127px" class="yt-form yt-dialog-form">
      <el-form-item label="岗位名称" prop="name">
        <span style="font-weight: bold">{{ formData.name }}</span>
      </el-form-item>
      <el-form-item label="主技能" prop="abilityId" style="padding-right: 51px">
        <el-select v-model="formData.abilityId" placeholder="请选择主技能" @visible-change="handleVisibleChange" @change="handleSkillSelect">
          <el-option v-for="item in skillDataCopy" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="技能描述" prop="description" style="padding-right: 31px">
        <div style="display: flex; align-items: center">
          <el-input v-model="formData.description" placeholder="请输入技能描述" :maxlength="300"></el-input>
          <el-tooltip effect="light" content="若没有找到对应技能，可去岗位技能知识点管理中创建" placement="top-start">
            <YTIcon :href="'#icon-tishi1'" style="color: #448bff; font-size: 16px; margin-left: 4px"></YTIcon>
          </el-tooltip>
        </div>
      </el-form-item>
      <div style="display: flex; align-items: center; margin-bottom: 30px">
        <el-form-item label="技能权重" prop="weight" class="form-weight">
          <el-input-number
            v-model="formData.weight"
            @change="computeRate"
            :controls="false"
            :min="0"
            :max="999"
            :precision="1"
            placeholder="请输入"
            style="width: 91px"
          ></el-input-number>
        </el-form-item>
        <el-tooltip effect="light" content="技能比例通过该岗位下所有技能配置的权重自动计算得出" placement="top-start">
          <YTIcon :href="'#icon-tishi1'" style="color: #448bff; font-size: 16px; margin-left: 12px"></YTIcon>
        </el-tooltip>
        <el-form-item label="技能比例">
          <span style="font-weight:  bold">{{ Math.round(formData.rate * 1000) / 10 }}%</span>
        </el-form-item>
      </div>
    </el-form>
    <div class="config-list-wrapper">
      <ul class="config-list-column">
        <li style="width: 150px; padding-right: 20px;">评价级别</li>
        <li style="width: 208px">级别分数</li>
        <li style="width: 580px">级别描述</li>
        <li>操作</li>
      </ul>
      <template v-for="(r, index) in rankList">
        <ul :key="r.id" style="margin-bottom: 30px">
          <li style="width: 150px; padding-right: 20px;">
            <el-autocomplete
              v-model="r.name"
              :class="{ error: rankError === index }"
              :fetch-suggestions="queryNameSearch"
              suffix-icon="el-icon-arrow-down"
              placeholder="请输入"
              :maxlength="8"
              style="width: 100%"
            ></el-autocomplete>
          </li>
          <li style="width: 208px;display: flex; align-items: center; padding-right: 50px">
            <p style="width: 40px; text-align: center; flex-shrink: 0">{{ r.lowScore }}</p>
            <span style="margin-right: 5px"> - </span>
            <template v-if="index > 0 && index === rankList.length - 1">
              <span>100</span>
            </template>
            <template v-else>
              <el-autocomplete
                v-model="r.highScore"
                :class="{ 'has-error': r.isError, error: rankScoreError === index }"
                :fetch-suggestions="queryHighScoreSearch"
                suffix-icon="el-icon-arrow-down"
                placeholder="请输入"
                :maxlength="3"
                @change="computeScore($event, index)"
                @select="selectScore($event, index)"
                @input="value => value.replace(/[^0-9]/g, '')"
              ></el-autocomplete>
            </template>
            <span style="margin-left: 8px">%</span>
          </li>
          <li style="width: 580px; padding-right: 50px">
            <el-input
              v-model="r.description"
              placeholder="请输入级别描述"
              :class="{ error: rankDescriptionError === index }"
              :maxlength="200"
              style="width: 100%"
            ></el-input>
          </li>
          <li v-if="(index > 0 && index !== rankList.length - 1) || (index === rankList.length - 1 && rankList.length === 2)">
            <span style="cursor: pointer; color: #448bff" @click="deleteScoreItem(index)">删除</span>
          </li>
        </ul>
      </template>
      <el-button type="primary" size="small" @click="addScoreItem">
        <div>
          <i class="el-icon-circle-plus-outline" />
          <p>添加评分</p>
        </div>
      </el-button>
    </div>
    <!--    <div class="sub-skill-header">-->
    <!--      <div style="padding-left: 51px">子技能配置</div>-->
    <!--    </div>-->
    <!--    <div class="config-list-wrapper">-->
    <!--      <ul class="config-list-column">-->
    <!--        <li style="width: 131px">子技能主题</li>-->
    <!--        <li style="width: 102px">权重</li>-->
    <!--        <li style="width: 70px">比例</li>-->
    <!--        <li style="width: 183px">描述</li>-->
    <!--        <li style="width: 183px">不合格评价(<50%)</li>-->
    <!--        <li style="width: 132px">推荐课程</li>-->
    <!--        <li style="width: 149px">推荐章节</li>-->
    <!--        <li>操作</li>-->
    <!--      </ul>-->
    <!--      <div v-for="(subSkill, index) in subSkillList">-->
    <!--        <ul :key="subSkill.id" style="margin-bottom: 30px; align-items: flex-start">-->
    <!--          <li style="width: 131px">-->
    <!--            <el-autocomplete-->
    <!--              v-model="subSkill.name"-->
    <!--              placeholder="请输入"-->
    <!--              :popper-append-to-body="false"-->
    <!--              :fetch-suggestions="querySearch"-->
    <!--              @select="handleSubSkillNameSelect($event, index)"-->
    <!--              @change="handleSubSkillNameInput($event, index)"-->
    <!--              style="width: 123px"-->
    <!--              :class="{ error: subSkillNameError === index }"-->
    <!--            ></el-autocomplete>-->
    <!--          </li>-->
    <!--          <li style="width: 102px">-->
    <!--            <el-input-number-->
    <!--              v-model="subSkill.weight"-->
    <!--              @change="computeSubSkillRate"-->
    <!--              :min="0"-->
    <!--              :max="99999"-->
    <!--              :precision="1"-->
    <!--              :controls="false"-->
    <!--              placeholder="请输入"-->
    <!--              style="width: 91px"-->
    <!--              :class="{ error: subSkillWeightError === index }"-->
    <!--            ></el-input-number>-->
    <!--          </li>-->
    <!--          <li style="width: 70px; height: 38px; line-height: 38px">{{ Math.round(subSkill.rate * 1000) / 10 }}%</li>-->
    <!--          <li style="width: 183px">-->
    <!--            <el-input-->
    <!--              v-model="subSkill.description"-->
    <!--              placeholder="请输入"-->
    <!--              :class="{ error: subSkillDescriptionError === index }"-->
    <!--              :maxlength="200"-->
    <!--              style="width: 172px"-->
    <!--            ></el-input>-->
    <!--          </li>-->
    <!--          <li style="width: 183px">-->
    <!--            <el-input-->
    <!--              v-model="subSkill.unqualifiedRemark"-->
    <!--              :class="{ error: subSkillUnqualifiedError === index }"-->
    <!--              placeholder="请输入"-->
    <!--              :maxlength="200"-->
    <!--              style="width: 174px"-->
    <!--            ></el-input>-->
    <!--          </li>-->
    <!--          <li style="width: 132px">-->
    <!--            <template v-for="(course, courseIndex) in subSkill.addCourseForms">-->
    <!--              <div class="course-item" :key="courseIndex">{{ course.courseName }}</div>-->
    <!--            </template>-->
    <!--            &lt;!&ndash;添加课程下拉框&ndash;&gt;-->
    <!--            <el-dropdown placement="bottom-start" @visible-change="handleCourseDropdownChange($event, 'course-tree-' + subSkill.id, index)">-->
    <!--              <div class="dropdown-button"><YTIcon :href="'#icon-xinzeng'"></YTIcon>添加课程</div>-->
    <!--              <el-dropdown-menu slot="dropdown">-->
    <!--                <el-input v-model="courseKeyword" class="yt-search search" placeholder="通过课程名称筛选">-->
    <!--                  <i-->
    <!--                    slot="suffix"-->
    <!--                    class="el-input__icon el-icon-search"-->
    <!--                    @click="toFilterNode('course-tree-' + subSkill.id, courseKeyword, index)"-->
    <!--                  ></i>-->
    <!--                </el-input>-->
    <!--                <div class="list-container" style="height: calc(100vh - 500px);">-->
    <!--                  <el-tree-->
    <!--                    :data="courseData"-->
    <!--                    :props="courseProps"-->
    <!--                    node-key="courseId"-->
    <!--                    :ref="'course-tree-' + subSkill.id"-->
    <!--                    :filter-node-method="filterNode"-->
    <!--                    show-checkbox-->
    <!--                    check-strictly-->
    <!--                  >-->
    <!--                  </el-tree>-->
    <!--                </div>-->
    <!--                <div class="footer">-->
    <!--                  <el-button @click="resetCourseSelection('course-tree-' + subSkill.id)">重置</el-button>-->
    <!--                  <el-button type="primary" @click="setCourseSelection('course-tree-' + subSkill.id, subSkill.id)">确定</el-button>-->
    <!--                </div>-->
    <!--              </el-dropdown-menu>-->
    <!--            </el-dropdown>-->
    <!--          </li>-->
    <!--          <li style="width: 149px">-->
    <!--            <template v-for="(course, courseIndex) in subSkill.addCourseForms">-->
    <!--              <el-dropdown-->
    <!--                :key="courseIndex"-->
    <!--                placement="bottom-start"-->
    <!--                @visible-change="handleChapterDropdownChange($event, 'chapter-tree-' + subSkill.id + '-' + courseIndex, index, courseIndex)"-->
    <!--              >-->
    <!--                <div v-if="course.chapters.length === 0" class="dropdown-button chapter-item"><YTIcon :href="'#icon-xinzeng'"></YTIcon>添加章节</div>-->
    <!--                <div class="course-item" v-else>-->
    <!--                  <template v-for="(chapter, chapterIndex) in course.chapters">-->
    <!--                    <span :key="chapterIndex">{{ chapter.chapterName }}{{ chapterIndex < course.chapters.length - 1 ? ', ' : '' }}</span>-->
    <!--                  </template>-->
    <!--                </div>-->
    <!--                <el-dropdown-menu slot="dropdown" v-loading="chapterLoading" element-loading-text="加载中">-->
    <!--                  <el-input v-model="chapterKeyword" class="yt-search search" placeholder="通过章节名称筛选">-->
    <!--                    <i-->
    <!--                      slot="suffix"-->
    <!--                      class="el-input__icon el-icon-search"-->
    <!--                      @click="toFilterChapterNode('chapter-tree-' + subSkill.id + '-' + courseIndex, index, courseIndex)"-->
    <!--                    ></i>-->
    <!--                  </el-input>-->
    <!--                  <div class="list-container" style="height: calc(100vh - 500px);">-->
    <!--                    <el-tree-->
    <!--                      :data="chapterData"-->
    <!--                      :props="chapterProps"-->
    <!--                      node-key="chapterId"-->
    <!--                      :ref="'chapter-tree-' + subSkill.id + '-' + courseIndex"-->
    <!--                      :filter-node-method="filterChapterNode"-->
    <!--                      show-checkbox-->
    <!--                      check-strictly-->
    <!--                    >-->
    <!--                    </el-tree>-->
    <!--                  </div>-->
    <!--                  <div class="footer">-->
    <!--                    <el-button @click="resetChapterSelection('chapter-tree-' + subSkill.id + '-' + courseIndex)">重置</el-button>-->
    <!--                    <el-button type="primary" @click="setChapterSelection('chapter-tree-' + subSkill.id + '-' + courseIndex, index, courseIndex)"-->
    <!--                      >确定</el-button-->
    <!--                    >-->
    <!--                  </div>-->
    <!--                </el-dropdown-menu>-->
    <!--              </el-dropdown>-->
    <!--            </template>-->
    <!--          </li>-->
    <!--          <li style="height: 38px; line-height: 38px">-->
    <!--            <span style="cursor: pointer; color: #448bff" @click="deleteSubSkillItem(index)">删除</span>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--      <el-button type="primary" size="small" @click="addSubSkillItem">-->
    <!--        <div>-->
    <!--          <i class="el-icon-circle-plus-outline" />-->
    <!--          <p>添加子技能</p>-->
    <!--        </div>-->
    <!--      </el-button>-->
    <!--    </div>-->
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import abilityApi from '@api/ability'
import courseApi from '@api/course'
import chapterApi from '@api/chapter'
import newPostApi from '@api/newPost'
export default {
  name: 'PostSkillConfigDialog',
  components: { YTIcon },
  props: {
    rateEnum: {
      type: Array,
      default: []
    },
    highScoreEnum: {
      type: Array,
      default: []
    }
  },
  data() {
    const validateWeight = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error('权重不能为0'))
      } else {
        callback()
      }
    }
    const validateDescription = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/gm
      if (!reg.test(value)) {
        callback(new Error('描述不能全为空格'))
      } else if (value.length > 200) {
        callback(new Error('描述不能多于200个字'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      postSkillId: '',
      formData: {
        postId: '',
        name: '',
        abilityId: '',
        description: '',
        weight: 0,
        rate: 0
      },
      ruleValidate: {
        name: [{ required: true, message: '岗位名称不能为空' }],
        abilityId: [{ required: true, message: '请选择主技能', trigger: 'change' }],
        description: [
          { required: true, message: '主技能描述不能为空' },
          { validator: validateDescription, trigger: 'blur' }
        ],
        weight: [
          { required: true, message: '权重不能为空' },
          { validator: validateWeight, trigger: 'blur' }
        ]
      },
      loading: false,
      rankList: [
        {
          id: 0,
          name: '',
          lowScore: 0,
          highScore: '100',
          description: '',
          isError: false
        }
      ], //级别
      rankError: '',
      rankDescriptionError: '',
      rankScoreError: '',
      subSkillList: [], //子技能
      subSkillNameError: '',
      subSkillDescriptionError: '',
      subSkillWeightError: '',
      subSkillUnqualifiedError: '',
      skillData: [], //子技能菜单
      skillDataCopy: [], //过滤后的菜单
      courseKeyword: '',
      courseData: [], //推荐课程菜单
      courseProps: {
        label: 'courseName'
      },
      chapterLoading: false,
      chapterKeyword: '',
      chapterData: [], //推荐章节菜单
      chapterProps: {
        label: 'chapterName'
      }
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    open(mode, postData, postSkillData) {
      this.visible = true
      this.mode = mode
      this.formData.postId = postData.postId
      this.formData.name = postData.postName
      if (!mode) {
        this.postSkillId = postSkillData.postSkillId
        this.getAbility(postData.id, {
          value: postSkillData.name,
          id: postSkillData.abilityId
        })
        this.skillDataCopy.push({
          value: postSkillData.name,
          id: postSkillData.abilityId
        })
        this.formData.abilityId = parseInt(postSkillData.abilityId)
        this.formData.description = postSkillData.description
        this.formData.weight = postSkillData.weight
        this.formData.rate = postSkillData.rate
        this.rankList = postSkillData.skillLevels.map(item => {
          return { ...item, id: this.$generateUUID() }
        })
        this.subSkillList = postSkillData.subSkills.map(item => {
          item.id = this.$generateUUID()
          if (item.simpleCourses !== null) {
            item.addCourseForms = item.simpleCourses.map(c => {
              c.id = this.$generateUUID()
              if (c.chapters !== null) {
                c.chapterIds = c.chapters.map(chapter => {
                  return chapter.chapterId
                })
              } else {
                c.chapterIds = []
                c.chapters = []
              }
              return c
            })
          } else {
            item.addCourseForms = []
          }
          return item
        })
      } else {
        this.getAbility(postData.postId)
      }
    },
    submit() {
      this.$refs.form.validate(val => {
        if (val) {
          let reg = /^(?=.*\S).+$/
          //校验评价级别
          let rankNameError = this.rankList.findIndex(item => {
            return item.name === '' || !reg.test(item.name)
          })
          if (rankNameError > -1) {
            this.$message.error('评分级别不能为空或全为空格')
            this.rankError = rankNameError
            return
          } else {
            this.rankError = ''
          }
          let rankDescriptionError = this.rankList.findIndex(item => {
            return item.description !== '' && !reg.test(item.description)
          })
          if (rankDescriptionError > -1) {
            this.$message.error('级别描述不能全为空格')
            this.rankDescriptionError = rankDescriptionError
            return
          } else {
            this.rankDescriptionError = ''
          }
          let rankScoreError = this.rankList.findIndex(item => {
            return item.highScore === '' || item.highScore === undefined
          })
          if (rankScoreError > -1) {
            this.$message.error('级别分数不能为空')
            this.rankScoreError = rankScoreError
            return
          } else {
            this.rankScoreError = ''
          }
          //校验子技能
          // if (this.subSkillList.length > 0) {
          //   let subSkillNameEmptyError = this.subSkillList.findIndex(item => {
          //     return item.abilityId === '' && item.name === ''
          //   })
          //   let subSkillNameError = this.subSkillList.findIndex(item => {
          //     return item.abilityId === '' && item.name !== ''
          //   })
          //   if (subSkillNameError > -1) {
          //     this.$message.error('无效的子技能主题, 请重新选择')
          //     this.subSkillNameError = subSkillNameError
          //     return
          //   } else if (subSkillNameEmptyError > -1) {
          //     this.$message.error('子技能主题不能为空')
          //     this.subSkillNameError = subSkillNameEmptyError
          //     return
          //   } else {
          //     this.subSkillNameError = ''
          //   }
          //   let subSkillDescriptionError = this.subSkillList.findIndex(item => {
          //     return item.description !== '' && !reg.test(item.description)
          //   })
          //   if (subSkillDescriptionError > -1) {
          //     this.$message.error('子技能描述不能全为空格')
          //     this.subSkillDescriptionError = subSkillDescriptionError
          //     return
          //   } else {
          //     this.subSkillDescriptionError = ''
          //   }
          //   let subSkillWeightError = this.subSkillList.findIndex(item => {
          //     return item.weight === '' || item.weight === undefined || item.weight === 0
          //   })
          //   if (subSkillWeightError > -1) {
          //     this.$message.error('权重不能为空或者0')
          //     this.subSkillWeightError = subSkillWeightError
          //     return
          //   } else {
          //     this.subSkillWeightError = ''
          //   }
          //   let subSkillUnqualifiedError = this.subSkillList.findIndex(item => {
          //     return item.unqualifiedRemark === '' || !reg.test(item.unqualifiedRemark)
          //   })
          //   if (subSkillUnqualifiedError > -1) {
          //     this.$message.error('子技能不合格评价不能为空或全为空格')
          //     this.subSkillUnqualifiedError = subSkillUnqualifiedError
          //     return
          //   } else {
          //     this.subSkillUnqualifiedError = ''
          //   }
          // }
          this.loading = true
          if (this.mode) {
            this.create()
          } else {
            this.update()
          }
        }
      })
    },
    create() {
      let payload = {
        ...this.formData,
        // addSubSkillForms: this.subSkillList,
        addSkillLevelForms: this.rankList
      }
      newPostApi
        .addPostSkill(payload)
        .then(res => {
          if (res.res === true) {
            this.$message.success('添加技能成功')
            this.$emit('refresh', this.formData.postId)
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    update() {
      let payload = {
        postSkillForm: {
          ...this.formData,
          // addSubSkillForms: this.subSkillList,
          addSkillLevelForms: this.rankList
        },
        postSkillId: this.postSkillId
      }
      newPostApi
        .editPostSkill(payload)
        .then(res => {
          if (res.res === true) {
            this.$message.success('编辑技能成功')
            this.$emit('refresh')
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.formData = {
        postId: '',
        name: '',
        abilityId: '',
        description: '',
        weight: 0,
        rate: 0
      }
      this.$refs.form.resetFields()
      this.subSkillList = []
      this.rankList = [
        {
          id: 0,
          name: '',
          lowScore: 0,
          highScore: '100',
          description: '',
          isError: false
        }
      ]
      this.skillDataCopy = []
      this.skillData = []
    },
    computeRate() {
      this.$emit('computeRate', this.formData, rate => {
        this.formData.rate = rate ? rate : 0
      })
    },
    addScoreItem() {
      //添加一行评分
      if (this.rankList.length === 1) {
        this.$message.warning('请先修改第一行的数值')
        return
      }
      this.rankList.splice(this.rankList.length - 1, 0, {
        id: this.$generateUUID(),
        name: this.rankList[this.rankList.length - 1].name,
        lowScore: this.rankList[this.rankList.length - 2].highScore,
        highScore: undefined,
        description: this.rankList[this.rankList.length - 1].description,
        isError: false
      })
      this.$set(this.rankList[this.rankList.length - 1], 'lowScore', '')
      this.$set(this.rankList[this.rankList.length - 1], 'name', '')
      this.$set(this.rankList[this.rankList.length - 1], 'description', '')
    },
    deleteScoreItem(index) {
      //删除一行评分
      if (index === this.rankList.length - 1) {
        this.$set(this.rankList[0], 'highScore', 100)
      } else {
        this.$set(this.rankList[index], 'lowScore', this.rankList[index - 1].highScore)
      }
      this.rankList.splice(index, 1)
    },
    computeScore(text, index) {
      //输入评分后检验
      if (text === '' || text === undefined) {
        this.$message.error('权重不能为空')
        return
      }
      let value = parseInt(text)
      if (index === 0 && value < 100 && this.rankList.length === 1) {
        this.rankList.push({
          id: 1,
          name: '',
          lowScore: text,
          highScore: 100,
          description: '',
          isError: false
        })
      }
      if (value >= parseInt(this.rankList[index + 1].highScore)) {
        this.$message.warning(`请输入不大于${this.rankList[index + 1].highScore}的数值`)
        this.$set(this.rankList[index], 'highScore', '')
        this.$set(this.rankList[index], 'isError', true)
        return
      }
      if (index !== 0 && value <= parseInt(this.rankList[index - 1].highScore)) {
        this.$message.warning(`请输入不小于${this.rankList[index - 1].highScore}的数值`)
        this.$set(this.rankList[index], 'highScore', '')
        this.$set(this.rankList[index], 'isError', true)
        return
      }
      if (value < 100) {
        this.$set(this.rankList[index], 'isError', false)
        this.$set(this.rankList[index + 1], 'lowScore', text)
      }
    },
    selectScore(data, index) {
      this.$set(this.rankList[index], 'highScore', data.value)
      this.computeScore(data.value, index)
    },
    getAbility(postId, skillData) {
      //获取子技能下拉菜单
      abilityApi.getOrgAbility(postId).then(res => {
        this.skillData = res.res.map(item => {
          return {
            value: item.name,
            id: item.id,
            description: item.describe
          }
        })
        if (skillData) {
          this.skillData.push(skillData)
        }
      })
    },
    getCourse() {
      //获取课程菜单
      courseApi.getCourseName().then(res => {
        this.courseData = res.res
      })
    },
    addSubSkillItem() {
      this.subSkillList.push({
        id: this.$generateUUID(),
        name: '',
        abilityId: '',
        addCourseForms: [],
        description: '',
        rate: 0,
        unqualifiedRemark: '',
        weight: 0
      })
    },
    deleteSubSkillItem(index) {
      this.subSkillList.splice(index, 1)
      this.computeSubSkillRate()
    },
    handleVisibleChange(visible) {
      //处理主技能下拉菜单选项
      if (visible) {
        this.skillDataCopy = this.filterSkillList(true)
      } else {
        this.skillDataCopy = []
      }
    },
    handleSkillSelect(id) {
      let data = this.skillDataCopy.filter(item => {
        return item.id === id
      })
      this.$set(this.formData, 'description', data[0].description === null ? '' : data[0].description)
    },
    querySearch(queryString, cb) {
      let result = this.filterSkillList(false)
      if (queryString) {
        result = result.filter(skill => {
          return skill.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        })
      }
      cb(result)
    },
    filterSkillList(hasAbilityId) {
      //去除主技能&子技能已选择的技能
      return this.skillData.filter(skill => {
        let isRepeat = this.subSkillList.some(item => {
          return item.name === skill.value && item.abilityId === skill.id
        })
        return hasAbilityId ? !isRepeat : skill.id !== this.formData.abilityId && !isRepeat
      })
    },
    handleSubSkillNameSelect(data, index) {
      this.$set(this.subSkillList[index], 'abilityId', data.id)
      this.$set(this.subSkillList[index], 'description', data.describle)
    },
    handleSubSkillNameInput(data, index) {
      let result = this.skillData.filter(item => {
        return item.value === data
      })
      if (result.length === 1) {
        this.$set(this.subSkillList[index], 'abilityId', result[0].id)
        this.$set(this.subSkillList[index], 'description', result[0].describle)
      }
    },
    computeSubSkillRate() {
      //计算子技能权重比例
      let total = this.subSkillList.reduce((prev, cur) => {
        if (cur.weight === '' || cur.weight === undefined) {
          cur.weight = 0
        }
        return prev + cur.weight
      }, 0)
      if (total !== 0) {
        this.subSkillList = this.subSkillList.map(item => {
          item.rate = item.weight / total
          return item
        })
      }
    },
    handleCourseDropdownChange(visible, ref, index) {
      //添加课程下拉框关闭时清空搜索关键词
      if (!visible) {
        this.courseKeyword = ''
        this.toFilterNode(ref, this.courseKeyword, index)
      } else {
        let courseIds = this.subSkillList[index].addCourseForms.map(item => {
          return item.courseId
        })
        this.$refs[ref][0].setCheckedKeys(courseIds)
      }
    },
    toFilterNode(ref, keyword, index) {
      //课程/章节树过滤点击触发
      this.$refs[ref][0].filter(keyword)
      let courseIds = this.subSkillList[index].addCourseForms.map(item => {
        return item.courseId
      })
      this.$refs[ref][0].setCheckedKeys(courseIds)
    },
    filterNode(value, data) {
      //课程/章节树过滤
      if (!value) return true
      return data.courseName.indexOf(value) !== -1
    },
    handleChapterDropdownChange(visible, ref, index, cIndex) {
      if (visible) {
        let course = this.subSkillList[index].addCourseForms[cIndex]
        chapterApi.getChapterSimpleByCourseId(course.courseId).then(res => {
          this.chapterData = res.res
          this.$refs[ref][0].setCheckedKeys(course.chapterIds)
        })
      } else {
        this.chapterKeyword = ''
        this.chapterData = []
      }
    },
    toFilterChapterNode(ref, index, courseIndex) {
      //课程/章节树过滤点击触发
      this.$refs[ref][0].filter(this.chapterKeyword)
      let chapterIds = this.subSkillList[index].addCourseForms[courseIndex].chapterIds
      this.$refs[ref][0].setCheckedKeys(chapterIds)
    },
    filterChapterNode(value, data) {
      //章节树过滤
      if (!value) return true
      return data.chapterName.indexOf(value) !== -1
    },
    setCourseSelection(ref, id) {
      //设置选中的课程
      this.subSkillList = this.subSkillList.map(item => {
        if (id === item.id) {
          let list = this.$refs[ref][0].getCheckedNodes().map(item1 => {
            let activeIndex = item.addCourseForms.findIndex(course => {
              return course.courseId === item1.courseId
            })
            if (activeIndex !== -1) {
              return {
                ...item1,
                id: this.$generateUUID(),
                chapterIds: item.addCourseForms[activeIndex].chapterIds,
                chapters: item.addCourseForms[activeIndex].chapters
              }
            } else {
              return {
                ...item1,
                id: this.$generateUUID(),
                chapterIds: [],
                chapters: []
              }
            }
          })
          this.$set(item, 'addCourseForms', list)
        }
        return item
      })
    },
    resetCourseSelection(ref) {
      this.$refs[ref][0].setCheckedNodes([])
    },
    setChapterSelection(ref, index, courseIndex) {
      this.$set(this.subSkillList[index].addCourseForms[courseIndex], 'chapters', this.$refs[ref][0].getCheckedNodes())
      this.$set(this.subSkillList[index].addCourseForms[courseIndex], 'chapterIds', this.$refs[ref][0].getCheckedKeys())
      this.$forceUpdate()
    },
    resetChapterSelection(ref) {
      //清空章节选择
      this.$refs[ref][0].setCheckedKeys([])
    },
    //utils
    queryNameSearch(queryString, cb) {
      let rates = this.rateEnum
      let results = queryString
        ? rates.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          })
        : rates
      cb(results)
    },
    queryHighScoreSearch(queryString, cb) {
      let rates = this.highScoreEnum
      let results = queryString
        ? rates.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          })
        : rates
      // 调用 callback 返回建议列表的数据
      cb(results)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/filterDropdownMenu';
.yt-dialog {
  ::v-deep .el-dialog {
    width: 1080px;
    .el-dialog__body {
      padding-right: 0;
      padding-left: 0;
      color: #000000;
    }
  }
}
.yt-form {
  border-bottom: 1px solid #eee;
  .el-form-item {
    margin-bottom: 30px;
    ::v-deep .el-form-item__label {
      font-weight: normal !important;
    }
  }
  .el-select {
    width: 100%;
  }
  ::v-deep .el-input {
    width: 100%;
  }
}
.form-weight {
  margin-bottom: 0 !important;
  ::v-deep .el-input {
    width: 90px;
  }
}
.config-list-wrapper {
  padding: 0 42px 0 50px;
  ul {
    .flexStyle(flex; flex-start);
  }
  .config-list-column {
    font-weight: bold;
    margin: 20px 0;
  }
  .rank-score {
    width: 39px;
    ::v-deep .el-input__inner {
      padding: 0;
      text-align: center;
    }
    &.has-error {
      ::v-deep .el-input__inner {
        border-color: #ff5050;
      }
    }
  }
}
::v-deep .el-input__inner {
  height: 38px;
  text-align: left;
}
.sub-skill-header {
  margin-top: 40px;
  padding-bottom: 12px;
  font-size: @default;
  font-weight: bold;
  color: #448bff;
  border-bottom: 1px solid #eee;
}
.dropdown-button {
  height: 38px;
  line-height: 38px;
  color: #448bff;
  cursor: pointer;
  svg {
    margin-right: 6px;
  }
}
.course-item {
  width: 110px;
  height: 38px;
  line-height: 38px;
  padding: 0 12px;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chapter-item {
  width: 117px;
  margin-bottom: 20px;
}
.el-dropdown {
  display: block;
}
.error {
  ::v-deep .el-input__inner {
    border-color: #f56c6c;
  }
}
::v-deep .el-autocomplete-suggestion {
  width: auto !important;
}
</style>
